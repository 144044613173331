import {
    changeFieldDisabled,
    changeFieldProperty,
    setFieldListOptions, setFormButtons
} from "../../../apps/KpModule/actions"
import Errors from '../../utils/Errors'
import {getDataListList} from "../../../apps/KpModule/selectors"
import {getFieldModuleForm} from "./themeConfig";
import {generateFetchFieldListAction} from "../../../apps/KpModule/actions/api"
import {groupIdForMongoQuery} from "./engines/alert/mongoPipeline"
import {basicContext} from "../../utils/contextUtils";

const async = require("async");
const moment = require("moment");
const _ = require("lodash");
const { setFieldVisibility } = require("../../../apps/KpModule/actions")

const todayISO = moment().format("YYYY-MM-DD");
const lastWeekISO = moment().subtract(7, "days").format("YYYY-MM-DD");

const cacheOnContext = function(context, callback) {
    // if alertsByConfigs is not yet computed, compute it

    if (!context.data || !context.data.alertsByConfigs) {
        // let t = new Date();
        userAlertsByAlertConfig(context, (e, alertsByConfigs) => {
            if(e) return callback(e);

            context.data = context.data ? context.data : {};
            _.assign(context.data, {alertsByConfigs});

            // const t2 = new Date();
            // console.log("alertConfig cache preparation duration: " + (t2 - t) + "ms");

            callback(null, context);
        });
    } else {
        callback(null, context);
    }
};

const userAlertsByAlertConfig = function(context, callback) {
    // creates a collection of {alertConfiguration: {..}, alerts: [..] }
    // it contains all alerts in date filter and that user has access

    async.waterfall(
        [
            async.constant(_.defaults({}, context)),

            (wfContext, callback) => global.app.S.User.getUserHabFunctions(
                wfContext.user,
                context,
                (e, userHabFunctions) => callback(e, {...wfContext, userHabFunctions})
            ),
            (wfContext, callback) => global.app.S.GroupAxis.find(
                {
                    ...basicContext(context),
                    query: {}
                }, (e, groupAxes) => callback(e, {...wfContext, groupAxes})
            ),
            (wfContext, callback) => {
                // check for id on context, that means we need only the alerts of the alert configuration
                // otherwise we list alerts belonging to all alert configurations
                if(context.id && (_.get(context, "access.entity.name") === "AlertConfiguration")) {
                    global.app.S.AlertConfiguration.collection.findOne(
                        {_id: global.ObjectID(context.id)},
                        (e, alertConfiguration) => callback(e, {...wfContext, userAlertConfigurations: [alertConfiguration]})
                    )

                } else {
                    global.app.S.AlertConfiguration.getAlertConfigurationsForHabFunctions(
                        wfContext.userHabFunctions,
                        (e, userAlertConfigurations) => callback(e, {...wfContext, userAlertConfigurations})
                    )
                }
            },

            (wfContext, callback) => {

                async.map(wfContext.userAlertConfigurations, function(alertConfiguration, callback) {

                    try {

                        if( ['00000000000000000000000d', '000000000000000000000002'].includes(alertConfiguration.timeWindow.toString())) {
                            const pipeline = global.app.S.AlertConfiguration.getYearAlertAggregationPipeline(alertConfiguration, wfContext)

                            global.app.S.Alert.collection
                                .aggregate(pipeline, { allowDiskUse: true })
                                .toArray((e, alerts) => {
                                    if(e) return callback(e);
                                    callback(null, {alertConfiguration, alerts, consultedAlertsNb: alerts.filter(alert => !!alert.consulted).length});
                                })
                        } else {
                            const query = global.app.S.AlertConfiguration.getAlertQueryFilter(alertConfiguration, wfContext);

                            global.app.S.Alert.collection
                                .find(query)
                                .toArray((e, alerts) => {
                                    if(e) return callback(e);
                                    callback(null, {alertConfiguration, alerts, consultedAlertsNb: alerts.filter(alert => !!alert.consulted).length})
                                })
                        }
                    } catch(e) {
                        return callback(e);
                    }

                }, callback);
            }
        ],
        callback
    );
};

// add StoreAxis if groupAxes contain any SubStoreAxis
const groupAxesWithStore = alertConfiguration => {
    /*
    const groupAxisOfType = type => alertConfiguration.groupAxes.some(
        groupAxis => groupAxis.joinedEntity === type
    );

    const isSubStore = groupAxisOfType("SubStoreAxis");
    const isStore = groupAxisOfType("StoreAxis");
     */

    return _.compact([
        ...alertConfiguration.groupAxes
    ]);
};

export const dependencies = [
    {
        name: "AggOperator",
        type: "static",
        fields: [
            "mongoOperator",
            "tKey"
        ],
        objects: [
            {id: "000000000000000000000001", tKey: "sum", mongoOperator: "$sum"},
            {id: "000000000000000000000002", tKey: "average", mongoOperator: "$avg"},
            {id: "000000000000000000000003", tKey: "maximum", mongoOperator: "$max"},
            {id: "000000000000000000000004", tKey: "minimum", mongoOperator: "$min"},
            {id: "000000000000000000000005", tKey: "existence"}
        ]
    },

    {
        name: "AlertConfCondition",
        type: "mongoInternal",
        fields: [
            {type: "ConditionType", nullable: true},
            {path: "stream", type: "InputCorrespondenceByImportType"},
            {path: "source", type: "InputCorrespondence", uniqueWith: ["operator", "condValue", "source2"]},
            {path: "source2", type: "InputCorrespondence", nullable: true},
            {path: "operator", type: "CondOperator"},
            "condValue",
            "condValues"
        ]
    },
    {
        name: "AlertEngineOrder",
        type: "static",
        fields: [
            {path: "id", type: "string"},
            "tKey", "tKey2"
        ],
        objects: [
            {
                id: "aggregationRatioCondition",
                tKey: "analysisMesh",
                tKey2: "analysisMesh",
            },
            {
                id: "ratioConditionAggregation",
                tKey: "elementaryMesh",
                tKey2: "intermediateMesh",
            }
        ]
    },
    {
        name: "DisplayedResult",
        type: "static",
        fields: [
            {path: "id", type: "string"},
            "name"
        ],
        objects: [
            {id: "indicators", name: "indicatorsSumAtAnalysisMesh"},
            {id: "occurrencesNumber", name: "occurrencesNumberAtAnalysisMesh"}
        ]
    },
    {
        name: "ManualAlertsInDatesForUser",
        type: "mongoInternal",
        fields: [
            {path: "consulted", type: "boolean"},
            {path: "dataPeriod"},
            {path: "meshWithoutGroupAxisLinks", type: "object"},
            {path: "uniqValuesByDelay", type: "object"}
        ]
    },
    {
        name: "AlertField",
        type: "mongoInternal",
        fields: [
            {path: "type", type: "RuleType"},
            "ThemeJoin",
            "ThemeFieldType",
            {
                path: "numeratorDelay",
                type: "integer"
            },
            {path: "denominatorTheme", type: "ThemeJoin", nullable: true},
            {path: "denominatorThemeFieldType", type: "ThemeFieldType", nullable: true},
            {
                path: "denominatorDelay",
                type: "integer"
            },
            {path: "aggOperator", type: "AggOperator", nullable: true},
            {path: "preAggOperator", type: "AggOperator", nullable: true},
            {path: "condOperator", type: "CondOperator", nullable: true},
            {path: "value", nullable: true, type: "decimal"},
            "AlertEngineOrder",
            {type: "DisplayedResult"},
            {
                path: "themeFieldPath",
                fieldPath: ["themeJoin.completeCode", "themeFieldType.code"],
                f: function() {
                    return this.themeJoin
                        ? this.themeJoin.completeCode + "." + this.themeFieldType.code
                        : undefined;

                }
            },
            {
                path: "themeStreamId",
                fieldPath: ["themeJoin.streamId"],
                f: function() {
                    return this.themeJoin.streamId
                }
            },
            {
                path: "denominatorThemeFieldPath",
                fieldPath: ["denominatorTheme.completeCode", "denominatorThemeFieldType.code"],
                f: function() {
                    return this.denominatorTheme
                        ? this.denominatorTheme.completeCode + "." + this.denominatorThemeFieldType.code
                        : undefined;
                }
            },
            {
                path: "denominatorThemeStreamId",
                fieldPath: ["themeJoin.streamId"],
                f: function() {
                    return this.denominatorTheme.streamId
                }
            },
            {
                path: "themeFieldPaths",
                lazy: true,
                fieldPath: ["themeFieldPath", "denominatorThemeFieldPath"],
                f: function() {
                    return _.compact([this.themeFieldPath, this.denominatorThemeFieldPath]);
                }
            },
            {
                path: "preGroupObjectForMongo",
                lazy: true,
                fieldPath: ["themeFieldPath", "themeFieldType.code", 'denominatorTheme', "denominatorThemeFieldPath", "denominatorThemeFieldType.code", "denominatorThemeLineCount", "preAggOperator.id", "preAggOperator.mongoOperator"],
                f: function() {

                    return ["theme", "denominatorTheme"].reduce((acc, fieldPath) => {
                        return Object.assign(
                            acc,
                            this[`${fieldPath}FieldPath`]
                                ? {
                                    [this[`${fieldPath}FieldPath`].replace(/\./g,' ')]: this.preAggOperator?.id === "000000000000000000000005"
                                        ? {
                                        "$max": {
                                                "$cond": [{ "$ifNull": [`$${this[`${fieldPath}FieldPath`]}`, false] }, 1, 0]
                                            }
                                        }
                                        : {
                                            [this.preAggOperator.mongoOperator]: `$${this[`${fieldPath}FieldPath`]}`
                                        }
                                }
                                : {}
                        )
                    }, {})

                }
            },
            {
                path: "themeFieldPathsWithNumeratorOrDenominator",
                lazy: true,
                fieldPath: ["themeFieldPath", "denominatorThemeFieldPath"],
                f: function() {
                    return _.compact([
                        this.themeFieldPath && `numerator-${this.themeFieldPath}`,
                        this.denominatorThemeFieldPath && `denominator-${this.denominatorThemeFieldPath}`
                    ]);
                }
            }
        ]
    }
]

export const entity = {
    name: "AlertConfiguration",
    facets: ["codeName", "description", "translatedField"],
    fields: [
        "TimeWindow",
        {path: "hasTimeInterval", type: "boolean"},
        "startTime",
        "endTime",
        {path: "fromFiscalYearStart", type: "boolean"},
        {path: "filterFields", type: "Scope", link: "MTM"},
        {
            path: "preGroupAxes",
            type: "GroupAxis",
            link: "MTM",
            nullable: true
        },
        {
            path: "groupAxes",
            type: "GroupAxis",
            link: "MTM",
            nullable: true
        },
        {
            path: "habFunctionHabilitation",
            type: "HabFunction",
            link: {type: "MTM", oppositeField: {link: {deleteType: "block"}}}
        },
        {path: "conditions", type: "AlertConfCondition", link: "OTM"},
        {type: "AlertField", link: "OTM", notEmpty: true},
        {type: "Alert", link: {type: "OTM", deleteType: "cascade", oppositeField: {index: true}}},
        {type: "WeekDay", link: "MTO", nullable: true},
        {type: "MonthDay", link: "MTO", nullable: true},
        {path: "sendJournal", type: "boolean", nullable: true},
        {path: "dormant", type: "boolean", default: false},
        {
            path: "reason",
            type: "Reason",
            link: {type: "MTM", oppositeField: {link: {deleteType: "block"}}}
        },
        {
            type: "integer",
            path: "delay",
            notEmpty: true
        },
        {
            path: "filterFieldsRefs",
            lazy: true,
            fieldPath: ["filterFields.name"],
            $f: function(alertConfiguration, context, callback) {
                callback(null, alertConfiguration.filterFields.length ? alertConfiguration.filterFields.map(filterField => filterField.name).join(", ") : "All");
            }
        },
        {
            path: "groupAxesRefs",
            lazy: true,
            fieldPath: ["groupAxes.name"],
            $f: function(alertConfiguration, context, callback) {
                callback(null, alertConfiguration.groupAxes.map(groupeAxes => groupeAxes.name).join(", "));
            }
        },
        {
            path: "alertsInDatesForUser",
            type: "ManualAlertsInDatesForUser",
            lazy: true,
            $f: function (alertConfiguration, context, callback) {
                cacheOnContext(
                    context,
                    (e, context) => {

                        const alertsByConfig = _.find(
                            context.data.alertsByConfigs,
                            alertsByConfig => alertsByConfig.alertConfiguration._id.toString() === alertConfiguration.id
                        )

                        if(!alertsByConfig || !alertsByConfig.alerts) {
                            callback(e, [])
                        }
                        else {

                            global.app.S.Alert.find(
                                {
                                    group: context.group,
                                    fieldPath: ["id", "code", "dataPeriod", "lineCount", "uniqValuesByDelay", "meshWithoutGroupAxis", "meshWithoutGroupAxisLinks", "consulted", "lastUpdate", "lastUpdateBy.name"],
                                    query: {
                                        _id: {$in: _.map(alertsByConfig.alerts, "_id")}
                                    }
                                },
                                (e, alerts) => {
                                    callback(e, alerts)
                                }
                            )
                        }


                    }
                );
            },
            $s: (alerts, alertConfiguration, context, callback) => {
                alertConfiguration.updatedAlerts = alerts

                callback();
            }
        },
        //{ path: "updatedAlerts"},
        {
            path: "alertNb",
            lazy: true,
            $f: function (object, context, callback) {
                cacheOnContext(
                    context,
                    (e, context) => {
                        const alertsByConfig = _.find(
                            context.data.alertsByConfigs,
                            alertsByConfig => alertsByConfig.alertConfiguration._id.toString() === object.id
                        );
                        const alerts = _.get(alertsByConfig, "alerts", []);
                        callback(null, alerts.length);
                    }
                );
            }
        },
        {
            path: "consultedAlertsNb",
            //lazy: true,
            $f: function (object, context, callback) {
                cacheOnContext(
                    context,
                    (e, context) => {
                        const alertsByConfig = _.find(
                            context.data.alertsByConfigs,
                            alertsByConfig => alertsByConfig.alertConfiguration._id.toString() === object.id
                        );
                        const consultedAlertsNb = _.get(alertsByConfig, "consultedAlertsNb", 0)
                        callback(null, consultedAlertsNb)
                    }
                )
            }
        },
        {
            path: "activationDay",
            fieldPath: ["weekDay", "monthDay"],
            f: function() {
                return this.weekDay
                    ? (this.monthDay ? this.weekDay.name + ", " + this.monthDay.name : this.weekDay.name)
                    : (this.monthDay ? this.monthDay.name : "Every Day");
            }
        },
        {
            path: "groupAxisName",
            lazy: true,
            fieldPath: ["groupAxes.id"],
            $f: (alertConfiguration, context, callback) => callback(
                null,
                groupAxesWithStore(alertConfiguration).map(
                    groupAxis => global.app.S.GroupAxis.translateName(groupAxis, context)
                ).join(" | ")
            )
        },
        {
            path: "themesKeysUniqByDelay",
            lazy: true,
            fieldPath: [
                "alertFields.themeJoin.completeCode",
                "alertFields.themeJoin.completeCodeWithoutPrefix",
                "alertFields.themeFieldType.code",
                "alertFields.denominatorTheme.completeCode",
                "alertFields.denominatorTheme.completeCodeWithoutPrefix",
                "alertFields.denominatorThemeFieldType.code",
                "alertFields.displayedResult.id"
            ],
            f: function(){
                return _(this.alertFields)
                    .flatMap((alertField, index) => {
                        if(alertField.displayedResult?.id === 'occurrencesNumber') {
                            return [{displayLineCount: true}]
                        }
                        return [
                            {themeJoin: alertField.themeJoin, fieldType: alertField.themeFieldType, prefix: `${index}-numerator-theme`,  delay: alertField.numeratorDelay},
                            {themeJoin: alertField.denominatorTheme, fieldType: alertField.denominatorThemeFieldType, prefix: `${index}-denominator-theme`, delay: alertField.denominatorDelay}
                        ]
                    })
                    .filter(element => element.displayLineCount || !!element.themeJoin)
                    .uniqBy(o => {
                        return o.displayLineCount || o.themeJoin.completeCode + o.fieldType.code + o.delay
                    })
                    .map(
                        o => {
                            return o.displayLineCount
                                ? `lineCount`
                                : `${o.prefix}-${o.themeJoin.completeCodeWithoutPrefix}.${o.fieldType.code}`
                        }
                    )
                    .value();
            }
        },
        {
            path: "themeNames",
            lazy: true,
            $f: function (object, context, callback) {
                global.app.S.AlertConfiguration.get(
                    object.id,
                    {
                        group: context.group,
                        fieldPath: ["themesNamesWithDelay"],
                    },
                    (e, alertConf) => {
                        return callback(null, alertConf.themesNamesWithDelay.join(";"));
                    }
                )
            }
        },
        {
            path: "brutSummedAlertsValues",
            lazy: true,
            $f: function (object, context, callback) {
                cacheOnContext(
                    context,
                    (e, context) => {
                        const alertsByConfig = _.find(
                            context.data.alertsByConfigs,
                            alertsByConfig => alertsByConfig.alertConfiguration._id.toString() === object.id
                        );
                        const alerts = _.get(alertsByConfig, "alerts", [])


                        global.app.S.AlertConfiguration.get(
                            object.id,
                            {
                                group: context.group,
                                fieldPath: ["themesKeysUniqByDelay"],
                            },
                            (e, alertConf) => {
                                const result = alerts.reduce((acc, alert) => {
                                    return alertConf.themesKeysUniqByDelay.map((key, index) => acc[index] + _.get(alert, key, 0))

                                }, Array(alertConf.themesKeysUniqByDelay.length).fill(0))

                                callback(
                                    null,
                                    result
                                        .map(value => _.round(value))
                                        .join(' | ')
                                );
                            }
                        )
                    }
                );
            }
        },
        {
            path: "summedAlertsValues",
            lazy: true,
            $f: function (object, context, callback) {
                cacheOnContext(
                    context,
                    (e, context) => {
                        const alertsByConfig = _.find(
                            context.data.alertsByConfigs,
                            alertsByConfig => alertsByConfig.alertConfiguration._id.toString() === object.id
                        );
                        const alerts = _.get(alertsByConfig, "alerts", []);
                        const alertConfId = _.get(alertsByConfig, "alertConfiguration._id");


                        global.app.S.AlertConfiguration.get(
                            alertConfId.toString(),
                            {
                                group: context.group,
                                fieldPath: ["themesKeysUniqByDelay"],
                            },
                            (e, alertConf) => {
                                const result = alerts.reduce((acc, alert) => {
                                    return alertConf.themesKeysUniqByDelay.map((key, index) => acc[index] + _.get(alert, key, 0))

                                }, Array(alertConf.themesKeysUniqByDelay.length).fill(0))

                                callback(
                                    null,
                                    result
                                        .map(value => _.round(value))
                                        .reduce((acc, value, index) => ({...acc, [`indicator${index + 1}`]: value}), {})
                                );
                            }
                        )
                    }
                );
            }
        },
        {
            path: "themesNamesWithDelay",
            lazy: true,
            fieldPath: [
                "alertFields.themeJoin.completeCode",
                "alertFields.themeJoin.completeName",
                "alertFields.themeFieldType.code",
                "alertFields.denominatorTheme.completeCode",
                "alertFields.denominatorTheme.completeName",
                "alertFields.denominatorThemeFieldType.code",
                "alertFields.displayedResult.id"
            ],
            f: function() {
                return _(this.alertFields)
                    .flatMap(alertField => {
                        if(alertField.displayedResult?.id === 'occurrencesNumber') {
                            return [{displayLineCount: true}]
                        }
                        return [
                            {themeJoin: alertField.themeJoin, fieldType: alertField.themeFieldType, delay: alertField.numeratorDelay},
                            {themeJoin: alertField.denominatorTheme, fieldType: alertField.denominatorThemeFieldType, delay: alertField.denominatorDelay}
                        ]
                    })
                    .filter(element => element.displayLineCount || !!element.themeJoin)
                    .uniqBy(o => {
                        return o.displayLineCount || o.themeJoin.completeCode + o.fieldType.code + o.delay
                    })
                    .map(
                        o => {
                            return o.displayLineCount
                                ? `Nombre d'occurrences`
                                : o.delay
                                    ? `${o.themeJoin.completeName} > ${o.fieldType.code} avec un décalage de ${o.delay}`
                                    : `${o.themeJoin.completeName} > ${o.fieldType.code}`
                        }
                    )
                    .value();
            }
        },
        {
            path: "themesNameWithDelay",
            lazy: true,
            fieldPath: ["themesNamesWithDelay"],
            f: function(){
                return this.themesNamesWithDelay.join(";");
            }
        },
        {
            path: "themesKeys",
            lazy: true,
            fieldPath: [
                "alertFields.themeJoin.completeCodeWithoutPrefix",
                "alertFields.themeFieldType.code",
                "alertFields.denominatorTheme.completeCodeWithoutPrefix",
                "alertFields.denominatorThemeFieldType.code"
            ],
            f: function(){
                return _(this.alertFields)
                    .flatMap((alertField, index) => [
                        {themeJoin: alertField.themeJoin, fieldType: alertField.themeFieldType, prefix: `${index}-numerator-theme`},
                        {themeJoin: alertField.denominatorTheme, fieldType: alertField.denominatorThemeFieldType, prefix: `${index}-denominator-theme`}
                    ])
                    .filter("themeJoin")
                    .map(
                        o => `${o.prefix}-${o.themeJoin.completeCodeWithoutPrefix}.${o.fieldType.code}`
                    )
                    .uniq()
                    .value();
            }
        },
        {
            path: "dashboardThemeKeysObjects",
            lazy: true,
            fieldPath: [
                "alertFields.themeJoin.completeName",
                "alertFields.themeFieldType.code",
                "alertFields.themeJoin.completeCodeWithoutPrefix",
                "alertFields.denominatorTheme.completeName",
                "alertFields.denominatorTheme.completeCodeWithoutPrefix",
                "alertFields.denominatorThemeFieldType.code"
            ],
            $f: function(alertConfiguration, context, callback){
                const translate = name => context.tc
                    ? context.tc(name, _.get(context, 'language.id'))
                    : t => t
                return callback(null,
                    _(alertConfiguration.alertFields)
                        .flatMap((alertField, index) => [
                            {index, themeJoin: alertField.themeJoin, fieldType: alertField.themeFieldType, prefix: `${index}-numerator-theme`, name: alertField.themeJoin?.completeName},
                            {index, themeJoin: alertField.denominatorTheme, fieldType: alertField.denominatorThemeFieldType, prefix: `${index}-denominator-theme`, name: alertField.denominatorTheme?.completeName}
                        ])
                        .filter("themeJoin")
                        .map(
                            o => ({
                                id: `${o.prefix}-${o.themeJoin.completeCodeWithoutPrefix}`,
                                prefix: o.prefix,
                                path: `${o.prefix}-${o.themeJoin.completeCodeWithoutPrefix}.${o.fieldType.code}`,
                                name: `${translate('rule')} ${o.index + 1} : ${o.name} ${o.fieldType.code}`,
                            })
                        )
                        .value()
                )
            }
        },
        {
            path: "completeThemesNames",
            lazy: true,
            fieldPath: [
                "alertFields.themeJoin.completeName",
                "alertFields.themeFieldType.code",
                "alertFields.denominatorTheme.completeName",
                "alertFields.denominatorThemeFieldType.code"
            ],
            f: function(){
                return _(this.alertFields)
                    .flatMap(alertField => [
                        {themeJoin: alertField.themeJoin, fieldType: alertField.themeFieldType},
                        {themeJoin: alertField.denominatorTheme, fieldType: alertField.denominatorThemeFieldType}
                    ])
                    .filter("themeJoin")
                    .map(
                        o => `${o.themeJoin.completeName} > ${o.fieldType.code}`
                    )
                    .value()
            }
        },
        {
            path: "themesNames",
            lazy: true,
            fieldPath: [
                "alertFields.themeJoin.completeName",
                "alertFields.themeFieldType.code",
                "alertFields.denominatorTheme.completeName",
                "alertFields.denominatorThemeFieldType.code"
            ],
            f: function(){
                return _(this.alertFields)
                    .flatMap(alertField => [
                        {themeJoin: alertField.themeJoin, fieldType: alertField.themeFieldType},
                        {themeJoin: alertField.denominatorTheme, fieldType: alertField.denominatorThemeFieldType}
                    ])
                    .filter("themeJoin")
                    .map(
                        o => `${o.themeJoin.completeName} > ${o.fieldType.code}`
                    )
                    .uniq()
                    .value();
            }
        },
        {
            path: "themesName",
            lazy: true,
            fieldPath: ["themesNames"],
            f: function(){
                return this.themesNames.join(" | ");
            }
        },
        {
            path: "filterFieldsName",
            fieldPath: ["filterFields.name"],
            f: function(){
                return this.filterFields.length ? this.filterFields.map(filterField => filterField.name).join(", ") : "All";
            }
        },
        {
            path: "baseDay",
            fieldPath: ["delay"],
            lazy: true,
            $f: function (object, context, callback) {
                const baseDay = moment.utc(context.referenceDate).subtract(object.delay, "days").toDate();
                callback(null, baseDay);
            }
        },
        {
            path: "queryDates",
            fieldPath: ["timeWindow.quantity", "timeWindow.period"],
            lazy: true,
            $f: function (object, context, callback) {

                return global.app.S.GeneralSettings.collection.findOne(
                    {group: global.ObjectID(context.group.id)},
                    (error, generalSettings) => {
                        const periodEnd = moment.utc(context.referenceDate)
                            .add(1, "days")
                            .subtract(generalSettings.delay, "days")

                        if(generalSettings && object.timeWindow.id === '00000000000000000000000d'){
                            const fiscalYearStartDate = `${generalSettings.month}-${generalSettings.monthNumber}`

                            const fiscalYearStart = moment.utc(fiscalYearStartDate, 'MM-DD')

                            const periodStart = periodEnd.isSameOrAfter(fiscalYearStart)
                                ? fiscalYearStart
                                : fiscalYearStart.subtract(1, 'years')
                            return callback(null, {
                                $gte: periodStart,
                                $lt: periodEnd
                            })
                        }

                        if(object.timeWindow.id === '000000000000000000000002'){
                            const yearStart = moment.utc().startOf('year')

                            const periodStart = periodEnd.isSameOrAfter(yearStart)
                                ? yearStart
                                : yearStart.subtract(1, 'years')
                            return callback(null, {
                                $gte: periodStart,
                                $lt: periodEnd
                            })
                        }

                        return callback(null, {
                            $gte: moment.utc(context.referenceDate)
                                .add(1, "days")
                                .subtract(generalSettings.delay, "days")
                                .subtract(object.timeWindow.quantity, object.timeWindow.period),
                            $lt: periodEnd
                        });
                    }
                )
            }
        },
        {
            path: 'hasCalculatedData',
            $f: (alertConfiguration, context, callback) => {
                global.app.S.Alert.collection
                    .findOne(
                        {alertConfiguration: new global.ObjectID(alertConfiguration.id)},
                        (e, alert) => {
                            if (e) return callback(e)
                            callback(null, !!alert)
                        })
            }
        }
    ],
    filters: [
        {
            name: "sendJournal",
            query: function(context) {
                if(context.sendJournal) return {sendJournal: context.sendJournal};
            }
        },
        {
            name: "alertConfigurationsByHabFunctions",
            isDefault: false,
            async: true,
            query: function (context, callback) {
                global.app.S.User.getUserHabFunctions(
                    context.user,
                    context,
                    function (e, userHabFunctions) {
                        callback(
                            e,
                            {$or: [
                                    {habFunctionHabilitation: {$size: 0}},
                                    {habFunctionHabilitation: {$in: userHabFunctions.map(hf => new global.ObjectID(hf.id))}}
                                ]}
                        )
                    }
                )
            }
        },
        {
            name: "hasStoreAxis",
            isDefault: false,
            client: false,
            match: function (object) {
                return object.groupAxes.some(o => o.joinedEntity === "StoreAxis")
            }
        }
    ],
    ps: {
        context: [{
            $$u: function (context, callback) {
                if (this.options.accessType === "S" && context.restAction && context.restAction.crudType === "U") {
                    const fieldPathAlertConf = ["habFunctionHabilitation.organizationAxisJoin.joinedEntity"]
                    context.internalFieldPath.push(...fieldPathAlertConf);
                }
                callback(null, context);
            }
        }]
    },
    validateDelete: function (object, context, callback) {
        async.parallel([
            callback =>  global.app.S.Alert.collection.deleteMany({alertConfiguration: global.ObjectID(object.id), group: new global.ObjectID(context.group.id)}, callback),
            callback =>  global.app.S.WorkflowConfigsByAlert.collection.deleteMany({alertConfiguration: global.ObjectID(object.id), group: new global.ObjectID(context.group.id)}, callback),
            callback =>  global.app.S.WorkflowConfig.collection.deleteMany({ alertConfiguration: global.ObjectID(object.id), group: new global.ObjectID(context.group.id) }, callback),
            callback =>  global.app.S.StaticWorkflow.collection.deleteMany({alertConfiguration: global.ObjectID(object.id), group: new global.ObjectID(context.group.id)}, callback),
            callback =>  global.app.S.StatusUser.collection.deleteMany({alertConfiguration: global.ObjectID(object.id), group: new global.ObjectID(context.group.id)}, callback),
        ], (e) => {
            if(e) return callback(e)
            return callback(null)
        })
    },
    groupAxesValidations: (alertConf, context, callback) => {
        const groupAxes = alertConf.groupAxes;
        const habFunctionHabilitations = alertConf.habFunctionHabilitation;

        /*
        if(groupAxes.length > 2) {
            return callback(new Errors.ValidationError(context.tc("theyCantBeMoreThanTwoElements")));
        }
         */

        const orgAxisOccurences = _.filter(groupAxes, {joinedEntity: "OrganizationAxis"}).length;
        if( orgAxisOccurences > 1){
            return callback(new Errors.ValidationError(context.tc("theOrganizationalAxisMustBeSelectedOnce")));
        }

        if( _.filter(groupAxes, {joinedEntity: "FamilyAxis"}).length > 1){
            return callback(new Errors.ValidationError(context.tc("theFamilyAxisMustBeSelectedOnce")));
        }

        if(orgAxisOccurences === 1 && groupAxes.some(ga => _.includes(["SubStoreAxis", "StoreAxis"], ga.joinedEntity))){
            return callback(new Errors.ValidationError(context.tc("theOrganizationalAxisCantbeSelectedWhithStoreAxisOrSubStoreAxis")));
        }


        if( groupAxes.length > 1 &&
            groupAxes.every(ga => _.includes(["FamilyAxis", "ProductAxis"], ga.joinedEntity)) ){

            return callback(new Errors.ValidationError(context.tc("theFamilyAxisCantBeSelectedWithProduct")));
        }

        if( groupAxes.length === 1 &&
            _.includes(["ProductAxis", "FamilyAxis"], groupAxes[0].joinedEntity) &&
            habFunctionHabilitations.some(hFH => hFH.organizationAxisJoin.joinedEntity !== "CentralAxis") ) {

            return callback(new Errors.ValidationError(context.tc("youCanUseOnlyCentralHabilitation")));
        }

        if( groupAxes.some(ga => _.includes(["OrganizationAxis"], ga.joinedEntity)) &&
            habFunctionHabilitations.some(hFH => _.includes(["StoreAxis"], hFH.organizationAxisJoin.joinedEntity)) ) {

            return callback(new Errors.ValidationError(context.tc("youCantUseStoreAxisHabilitation")));
        }

        const alertFields = alertConf.alertFields
        if(alertFields.some(af => af.denominatorTheme && !af.denominatorThemeFieldType)){
            return callback(new Errors.ValidationError(context.tc("theDenominatorFieldIsRequiredWhenDenominatorIsSelected")));
        }

        return callback()
    },
    filterFieldsValidation: (alertConf, context, callback) => {

        const seenCodes = new Set()

        for (const field of alertConf.filterFields) {
            const code = field.scopeAxe?.code
            if (code) {
                if (seenCodes.has(code)) {
                    return callback(new Errors.ValidationError(context.tc("duplicateAxes")))
                }
                seenCodes.add(code);
            }
        }

        return callback()
    },
    validateSave: function(alertConf, oldAlertConf, context, callback) {
        const moduleId = _.get(context, "module.id")
        if(context.action === 'updateAlertStatus' && moduleId === "m-S-alerts") return callback()
        async.series(
            [
                callback => this.groupAxesValidations(alertConf, context, callback),
                callback => this.filterFieldsValidation(alertConf, context, callback)
            ],
            callback
        )
    },
    beforeSave: async function(alertConf, oldAlertConf, context, callback) {
        const crudType = context.restAction && context.restAction.crudType
        const moduleId = _.get(context, "module.id")

        if (crudType === 'C') {
            const automaticReason = await global.app.S.Reason.collection.findOne({automatic: true})
            if(automaticReason) {
                alertConf.reason = [{id: automaticReason._id.toString()}]
            }
            return callback(null, alertConf, oldAlertConf)
        }

        if(context.action === 'updateAlertStatus' && moduleId === "m-S-alerts") {
            const alertsByConfig = _.find(
                context.data.alertsByConfigs,
                alertsByConfig => alertsByConfig.alertConfiguration._id.toString() === alertConf.id
            );
            const previousAlertsMap = new Map(alertConf.alertsInDatesForUser.map(alert => [alert.id, alert]));

            const changedAlerts = alertConf.updatedAlerts.filter(alert =>
                previousAlertsMap.has(alert.id) && previousAlertsMap.get(alert.id).consulted !== alert.consulted
            )

            if(!!changedAlerts.length) {
                const alertsChangingToTrue = changedAlerts.filter(alert => alert.consulted === true)
                const alertsChangingToFalse = changedAlerts.filter(alert => alert.consulted === false)

                const previousConsultedAlertsNb = _.get(alertsByConfig, "consultedAlertsNb", 0)

                alertsByConfig.consultedAlertsNb = previousConsultedAlertsNb + alertsChangingToTrue.length - alertsChangingToFalse.length

                if(!!alertsChangingToTrue.length) {
                    await global.app.S.Alert.collection.updateMany(
                        {_id: {$in: alertsChangingToTrue.map(alert => new global.ObjectID(alert.id))}},
                        {
                            $set: {
                                consulted: true,
                                lastUpdate: new Date(),
                                lastUpdateBy: new global.ObjectID(context.user.id)
                            }
                        }
                    )
                }

                if(!!alertsChangingToFalse.length) {
                    await global.app.S.Alert.collection.updateMany(
                        {_id: {$in: alertsChangingToFalse.map(alert => new global.ObjectID(alert.id))}},
                        {
                            $set: {
                                consulted: false,
                                lastUpdate: new Date(),
                                lastUpdateBy: new global.ObjectID(context.user.id)
                            }
                        }
                    )
                }
            }
        }
        callback(null, alertConf, oldAlertConf)
    },
    getAlertConfigurationsForHabFunctions: function(uHabFuncs, callback) {
        this.collection
            .find({
                $or: [
                    //{habFunctionHabilitation: {$size: 0}},
                    {habFunctionHabilitation: {$in: uHabFuncs.map(hf => global.ObjectID(hf.id))}}
                ]
            })
            .toArray(callback);
    },
    getYearAlertAggregationPipeline: function(alertConfiguration, context) {
        const query = this.getAlertQueryFilter(alertConfiguration, context)

        const groupAxes = context.groupAxes.filter(groupAxis => {
            return alertConfiguration.groupAxes.map(gAxis => gAxis.toString()).includes(groupAxis.id)
        })

        const groupAxisSortObject = groupAxes.reduce(
            (acc, groupAxis) => Object.assign(acc, groupAxis.sortObjectForMongo),
            {}
        )

        return [
            {
                $match: query
            },
            {
                $sort: {
                    ...groupAxisSortObject,
                    alertCreationTime: -1
                }
            },
            {
                $group: {
                    _id: groupIdForMongoQuery(groupAxes),
                    firstElement: { $first: "$$ROOT" }
                }
            },
            {
                $replaceRoot: { newRoot: "$firstElement" }
            }
        ]
    },
    getAlertQueryFilter: function(alertConfiguration, context) {

        const habFunctionsByType = _(alertConfiguration.habFunctionHabilitation)
            .map(
                habFunction => _.find(context.userHabFunctions, {id: habFunction.toString()})
            )
            .compact()
            .groupBy(
                habFunction => _.lowerFirst(habFunction.organizationAxisJoin.joinedEntity)
            )
            .value();

        const storeAndOrganizationQuery = function() {
            if (!habFunctionsByType.centralAxis && (habFunctionsByType.storeAxis || habFunctionsByType.organizationAxis)) {
                const uStoreDbRefsInAlertConfig = _(habFunctionsByType.storeAxis || [])
                    .flatMap("habilitations")
                    .flatMap("organizationsJoin")
                    .map(oj => global.ObjectID(oj.id))
                    .value();

                const uOrganizationDbRefsInAlertConfig = _(habFunctionsByType.organizationAxis || [])
                    .flatMap("habilitations")
                    .flatMap("organizationsJoin")
                    .map(oj => global.ObjectID(oj.id))
                    .value();

                return {$or: _.compact([
                        uOrganizationDbRefsInAlertConfig.length && {organizations: {$in: uOrganizationDbRefsInAlertConfig}},
                        uStoreDbRefsInAlertConfig.length && {store: {$in: uStoreDbRefsInAlertConfig}}
                    ])};

            } else {
                return {};
            }
        }();

        let dates;

        if (_.get(context, "data.period[0]") && _.get(context, "data.period[1]")) {
            dates = context.data.period;
        } else {
            console.warn("Dates in context not found!");
            dates = ["2022-06-30", "2022-06-30"];
        }

        dates = dates.map(
            date => moment.utc(date.trim(), "YYYY-MM-DD")
        );

        const baseQuery = {
            alertConfiguration: global.ObjectID(alertConfiguration._id),
            /*
            "date.gte": {
                $gte: dates[0].toDate()
            },
             */
            "date.lt": {
                $gt: dates[0].toDate(),
                $lte: dates[1].add(1, "days").toDate()
            },
            group: global.ObjectID(context.group.id)
        };

        return _.defaults(baseQuery, storeAndOrganizationQuery);
    },
    groupAxesWithStore,
    find: function(context, callback) {
        this.prepareContext(context, 'L', (error, context) => {
            if (error) return callback(error)

            if(
                _.get(context, 'module.name') === 'Alerts' &&
                _.get(context, 'module.useSocketsOnFind')
            ){
                const socketType = _.get(context, 'clientContext.accessId').endsWith('chart')
                    ? 'Chart'
                    : 'DT'
                global.app.S.AlertConfiguration.defaultFind(
                    context,
                    (e, alertConfigs) => {
                        if(e) {
                            global.ioSocket.emit(
                                `fetch${socketType}-failure-${context.user.id}${context.clientContext.accessId}`,
                                {error: e.message}
                            )
                        } else {
                            global.ioSocket.emit(
                                `fetch${socketType}-success-${context.user.id}${context.clientContext.accessId}`,
                                alertConfigs
                            )

                        }
                    })
                callback(null, [])
            } else {
                global.app.S.AlertConfiguration.defaultFind(context, (e, alertConfigs) => {
                    if(e) return callback(e);
                    callback(null, alertConfigs)
                })
            }
        })
    }
}

export const module_ = {
    object: "AlertConfiguration",
    tKey: "mTitle_admin_alert",
    deletionConfirmationMessage: 'alertConfigurationDeletionConfirmationMessage',
    name: "AlertConfiguration",
    category: {
        path: 'setting',
        icon: 'settings'
    },
    defaultSortBy : "name",
    defaultSortDirection : "ASC",
    viewMap: {
        dt: [
            {path: "name", type: "translatedText", width: 400},
            {path: "filterFieldsName", tKey: "dataPerimeter"},
            {path: "groupAxisName", tKey: "analysisMesh"},
            {path: "timeWindow", tKey: "dataPeriod", display: "tKey", translate: true, width: 100},
            {path: "dormant", tKey: "suspended", width: 50},
            //{path: "delay", tKey: "timeLagInDays", width: 120},
            {path: "habFunctionHabilitation", tKey: "profile(s)", translateName: true, initiallyNotVisible: true}
        ],
        form: {
            fields: [
                "code",
                {path: "name", type: "textarea", required: true},
                "description",
                {path: "filterFields", tKey: "dataPerimeter", display: "completeName", fieldPath: ["id", "completeName", "scopeAxe.code"], required: false},
                {path: "groupAxes", tKey: "analysisMesh", display: "tName", required: true, filters: ["alertAnalysisAxes"]},
                {
                    path: "preGroupAxes",
                    tKey: "intermediateAnalysisMesh",
                    display: "tName",
                    subscriptions: {
                        onChange: (newValue, oldValue, {module, store}) => {
                            const alertFieldsField = module.viewMap.form.fields.find(field => field.path === "alertFields")
                            const getListField = path => alertFieldsField.viewMap.dt.fields.find(field => field.path === path )
                            const getFormField = path => alertFieldsField.viewMap.form.fields.find(field => field.path === path )
                            const aggOperatorListField = getListField("aggOperator")
                            const aggOperatorFormField = getFormField("aggOperator")
                            const preAggOperatorListField = getListField("preAggOperator")
                            const preAggOperatorFormField = getFormField("preAggOperator")

                            const ruleHasPreGroupAxes = !!newValue?.length

                            store.dispatch(setFieldVisibility(preAggOperatorListField.id, ruleHasPreGroupAxes))
                            store.dispatch(setFieldVisibility(preAggOperatorFormField.id, ruleHasPreGroupAxes))
                            if(ruleHasPreGroupAxes) {
                                store.dispatch(setFieldVisibility(aggOperatorFormField.id, true))
                                store.dispatch(changeFieldDisabled(aggOperatorFormField.id, false))
                            }
                            store.dispatch(changeFieldProperty(
                                aggOperatorListField.id,
                                "tKey",
                                ruleHasPreGroupAxes ? "analysisMeshOperation": "operation"
                            ))
                            store.dispatch(changeFieldProperty(
                                aggOperatorFormField.id,
                                "tKey",
                                ruleHasPreGroupAxes ? "analysisMeshOperation": "operation"
                            ))

                            store.dispatch(changeFieldProperty(
                                "e_alertFields.e_alertEngineOrder",
                                "display",
                                !!newValue?.length ? "tKey2": "tKey"
                            ))
                            store.dispatch(changeFieldProperty(
                                "e_alertFields.l_alertEngineOrder",
                                "display",
                                !!newValue?.length ? "tKey2": "tKey"
                            ))
                        }
                    }
                },
                {
                    path: "conditions",
                    tKey: "filteringConditions",
                    removable: true,
                    viewMap: {
                        dt: [
                            {path: "source", tKey: "cashierFileId"},
                            {path: "operator", translate: true},
                            {path: "source2", tKey: "cashierFileId2"},
                            {path: "condValue", tKey: "value"},
                            {path: "condValues", tKey: "values"}
                        ],
                        form: [
                            {
                                path: "conditionType",
                                type: "toggle",
                                sortList: false,
                                subscriptions: {
                                    onChange: (newValue, oldValue, {module, store}) => {
                                        const streamField = getFieldModuleForm("conditions", "stream", module)
                                        const operatorField = getFieldModuleForm("conditions", "operator", module)
                                        const valueField = getFieldModuleForm('conditions', 'condValue', module)
                                        const valuesField = getFieldModuleForm('conditions', 'condValues', module)
                                        const compareToField = getFieldModuleForm('conditions', 'source2', module)

                                        const operator = operatorField.getValue()
                                        const showValuesField = !!operator && ['000000000000000000000018', '000000000000000000000019'].includes(operator.id)
                                        const isApplyConditionType = !!newValue && newValue.id === "applyCondition"
                                        const isApplyComparisonType = !!newValue && newValue.id === "applyComparison"

                                        store.dispatch(
                                            setFieldVisibility(
                                                streamField.id,
                                                !!newValue
                                            )
                                        )
                                        store.dispatch(
                                            setFieldVisibility(
                                                valueField.id,
                                                isApplyConditionType && !showValuesField
                                            )
                                        )
                                        store.dispatch(
                                            setFieldVisibility(
                                                valuesField.id,
                                                isApplyConditionType && showValuesField
                                            )
                                        )

                                        if(isApplyConditionType) {
                                            store.dispatch(changeFieldProperty('e_conditions.e_source', 'tKey', 'cashierFileId'))
                                            compareToField.setValue(null)
                                        }

                                        if(isApplyComparisonType) {
                                            store.dispatch(changeFieldProperty('e_conditions.e_source', 'tKey', 'cashierFileId1'))
                                            valueField.setValue(null)
                                            valuesField.setValue(null)

                                            if(showValuesField) {
                                                operatorField.setValue(null)
                                            }
                                        }

                                        const defaultFields = ["source", "operator"]


                                        defaultFields.forEach(path => {
                                            store.dispatch(setFieldVisibility(`e_conditions.e_${path}`, !!newValue))
                                        })
                                        store.dispatch(setFieldVisibility(`e_conditions.e_source2`, isApplyComparisonType))

                                        if(!!newValue) {
                                            const state = store.getState()
                                            const options = getDataListList(
                                                state,
                                                'm-S-alertConfiguration.AlertConfCondition_operator'
                                            );
                                            const filteredOptions = options.filter(option => {
                                                if(newValue.id === "applyCondition") return true
                                                return !["000000000000000000000018", "000000000000000000000019"].includes(option.id)
                                            })

                                            store.dispatch(setFieldListOptions("e_conditions.e_operator", _.map(filteredOptions, "id")))
                                        }
                                    }
                                }
                            },
                            {
                                path: "stream",
                                type: "toggle",
                                subscriptions: {
                                    onChange: (newValue, oldValue, {store}) => {
                                        store.dispatch(
                                            generateFetchFieldListAction(
                                                "m-S-alertConfiguration.AlertConfCondition_source",
                                                store.getState,
                                                'form',
                                                {data: newValue }
                                            )
                                        )
                                        store.dispatch(
                                            generateFetchFieldListAction(
                                                "m-S-alertConfiguration.AlertConfCondition_source2",
                                                store.getState,
                                                'form',
                                                {data: newValue }
                                            )
                                        )
                                    }
                                },
                                hidden: true
                            },
                            {path: "source", tKey: "cashierFileId", fieldPath: ['id', 'name', 'inputCorrespondenceByImportType.id'], filters: ["byFlowStructure", "isAlertModelFilterAxis"], hidden: true},
                            {
                                path: "operator",
                                translate: true,
                                hidden: true,
                                subscriptions: {
                                    onChange: (newValue, oldValue, {module, store}) => {
                                        const conditionsField = module.viewMap.form.fields.find(field => field.path === 'conditions')
                                        const valueField = conditionsField.viewMap.form.fields.find(field => field.path === 'condValue')
                                        const valuesField = conditionsField.viewMap.form.fields.find(field => field.path === 'condValues')
                                        const conditionTypeField = conditionsField.viewMap.form.fields.find(field => field.path === 'conditionType')

                                        const conditionType = conditionTypeField.getValue()
                                        const isApplyConditionType = conditionType?.id === "applyCondition"

                                        const showValuesField = newValue && ['000000000000000000000018', '000000000000000000000019'].includes(newValue.id)

                                        store.dispatch(setFieldVisibility(valuesField.id, isApplyConditionType && showValuesField))
                                        store.dispatch(setFieldVisibility(valueField.id, isApplyConditionType && !showValuesField))

                                        if(!showValuesField) {
                                            valuesField.setValue(null)
                                        }

                                        if(showValuesField) {
                                            valueField.setValue(null)
                                        }
                                    }
                                }
                            },
                            {path: "condValue", tKey: "value", hidden: true},
                            {path: "condValues", tKey: "values", type: 'creatableTags', hidden: true},
                            {path: "source2", tKey: "cashierFileId2", fieldPath: ['id', 'code', 'name', 'inputCorrespondenceByImportType.id'], filters: ["byFlowStructure", "isAlertModelFilterAxis"], hidden: true},
                        ]
                    }
                },
                {
                    path: "timeWindow",
                    tKey: "dataPeriod",
                    display: "tKey",
                    translate: true,
                    clearable: false,
                    sortList: false,
                    default: {id: "00000000000000000000000a"},
                    subscriptions: {
                        onChange: (newValue, oldValue, {store}) => {

                            const tKeyByValueId = {
                                "00000000000000000000000a": "denominatorDelayByDay",
                                "00000000000000000000000b": "denominatorDelayByWeek",
                                "00000000000000000000000c": "denominatorDelayByMonth",
                                "00000000000000000000000d": "denominatorDelayByYear",
                                "00000000000000000000000e": "denominatorDelayByWeek",
                                "00000000000000000000000f": "denominatorDelayByMonth",
                                "000000000000000000000001": "denominatorDelayByYear",
                                "000000000000000000000002": "denominatorDelayByYear"
                            }
                            if (newValue) {
                                store.dispatch(changeFieldProperty("e_alertFields.e_denominatorDelay", "tKey", tKeyByValueId[newValue.id] ))
                            }
                        }
                    }
                },
                {
                    path: 'hasTimeInterval',
                    tKey: 'defineTimeInterval',
                    subscriptions: {
                        onChange: (newValue, oldValue, {store}) => {
                            store.dispatch(setFieldVisibility("e_startTime", !!newValue))
                            store.dispatch(setFieldVisibility("e_endTime", !!newValue))
                        }
                    }
                },
                {path: "startTime", tKey: "start", type: "timePicker"},
                {path: "endTime", tKey: "end", type: "timePicker"},
                // {path: "sendJournal", tKey: "includeInJournal"},
                {
                    path: "alertFields",
                    tKey: "rule_plural",
                    required: true,
                    removable: true,
                    viewMap: {
                        dt: [
                            {path: "alertEngineOrder", display: "tKey", fieldPath: ["id", "tKey", "tKey2"], translate: true, tKey: "computationOrder_short"},
                            {path: "themeJoin", tKey: "numerator", display: "completeName"},
                            {path: "themeFieldType", display: "tKey", translate: true, tKey: "amountOrNumber"},
                            {path: "denominatorTheme", tKey: "denominator", display: "completeName"},
                            {path: "denominatorThemeFieldType", display: "tKey", translate: true, tKey: "amountOrNumber"},
                            {path: "preAggOperator", display: "tKey", translate: true, tKey: "intermediateMeshOperation"},
                            {path: "aggOperator", display: "tKey", translate: true, tKey: "operation", hidden: true},
                            {path: "condOperator", display: "name", translate: true, tKey: "condition"},
                            {path: "value", tKey: "conditionValue"},
                        ],
                        form: [
                            {
                                path: "type",
                                type: 'toggle',
                                sortList: false,
                                subscriptions: {
                                    onChange: (newValue, oldValue, {module, store}) => {
                                        const preGroupAxesField = module.viewMap.form.fields.find(field => field.path === "preGroupAxes")
                                        const alertFieldsField = module.viewMap.form.fields.find(field => field.path === "alertFields")
                                        const getField = path => alertFieldsField.viewMap.form.fields.find(field => field.path === path )
                                        const simpleRuleFields = ["alertEngineOrder", "themeJoin", "themeFieldType", "condOperator", "value"].map(path => getField(path))
                                        const ratioRuleFields = ["denominatorTheme", "denominatorThemeFieldType", "denominatorDelay"].map(path => getField(path))

                                        const groupAxes = preGroupAxesField.getValue()
                                        const hasGroupAxes = !!groupAxes?.length
                                        const displayedResultField = getField("displayedResult")
                                        const preAggOperatorField = getField("preAggOperator")
                                        const aggOperatorField = getField("aggOperator")

                                        const alertOrderField = getField("alertEngineOrder")
                                        const alertOrder = alertOrderField.getValue()

                                        const isRatioConditionAggregation = alertOrder?.id === "ratioConditionAggregation"
                                        if(alertOrder?.id === "aggregationRatioCondition" ){
                                            displayedResultField.setValue({id: 'indicators'})
                                        }

                                        if(alertOrder?.id === "ratioConditionAggregation") {
                                            aggOperatorField.setValue({id: '000000000000000000000001'})
                                        }
                                        store.dispatch(setFieldVisibility(displayedResultField.id, !!newValue?.id && isRatioConditionAggregation))
                                        store.dispatch(changeFieldDisabled(aggOperatorField.id, !!newValue?.id && isRatioConditionAggregation))
                                        store.dispatch(setFieldVisibility(aggOperatorField.id, !!newValue?.id && (!isRatioConditionAggregation || hasGroupAxes)))
                                        simpleRuleFields.forEach(field => store.dispatch(setFieldVisibility(field.id, newValue && ["simple", "ratio"].includes(newValue.id))))
                                        ratioRuleFields.forEach(field => store.dispatch(setFieldVisibility(field.id, newValue?.id === "ratio")))
                                        store.dispatch(setFieldVisibility(preAggOperatorField.id, hasGroupAxes && newValue && ["simple", "ratio"].includes(newValue.id)))
                                        if(newValue?.id === 'simple') {
                                            ratioRuleFields.forEach(field => {
                                                if(field.path === 'denominatorDelay') {
                                                    field.setValue(0)
                                                    return
                                                }
                                                field.setValue(null)
                                            })
                                        }
                                        store.dispatch(changeFieldProperty("e_alertFields.e_themeJoin", "tKey", newValue?.id === "simple" ? "indicator" : "numerator"))

                                    }
                                }
                            },
                            {path: "alertEngineOrder", display: "tKey", fieldPath: ["id", "tKey", "tKey2"], translate: true, tKey: "computationOrder", hideLabel: false, default: {id: "ratioConditionAggregation"}, hidden: true,
                                subscriptions: {
                                    onChange: (newValue, oldValue, {module, store}) => {
                                        const alertFieldsField = module.viewMap.form.fields.find(field => field.path === "alertFields")
                                        const typeField = alertFieldsField.viewMap.form.fields.find(field => field.path === "type")
                                        const type = typeField.getValue()
                                        const displayedResultField = alertFieldsField.viewMap.form.fields.find(field => field.path === "displayedResult")
                                        const aggOperatorField = alertFieldsField.viewMap.form.fields.find(field => field.path === "aggOperator")
                                        const preGroupAxesField = module.viewMap.form.fields.find(field => field.path === "preGroupAxes")

                                        const groupAxes = preGroupAxesField.getValue()
                                        const hasGroupAxes = !!groupAxes?.length

                                        const isRatioConditionAggregation = newValue?.id === "ratioConditionAggregation"
                                        if(newValue?.id === "aggregationRatioCondition") {
                                            displayedResultField.setValue({id: "indicators"})
                                        }
                                        if(newValue?.id === "ratioConditionAggregation") {
                                            aggOperatorField.setValue({id: "000000000000000000000001"})
                                        }
                                        store.dispatch(setFieldVisibility(displayedResultField.id,!!type?.id && newValue?.id === 'ratioConditionAggregation'))
                                        store.dispatch(changeFieldDisabled(aggOperatorField.id,!!type?.id && newValue?.id === 'ratioConditionAggregation' && !hasGroupAxes))
                                        store.dispatch(setFieldVisibility(aggOperatorField.id, !!type?.id && (!isRatioConditionAggregation || hasGroupAxes)))
                                    }
                                }
                            },
                            {path: "themeJoin", tKey: "numerator", display: "completeName", hidden: true},
                            {path: "themeFieldType", display: "tKey", translate: true, tKey: "amountOrNumber", type: 'toggle', default: {id: "000000000000000000000101"}, hidden: true},
                            {path: "denominatorTheme", tKey: "denominator", display: "completeName", required: true, hidden: true},
                            {path: "denominatorThemeFieldType", display: "tKey", translate: true, tKey: "amountOrNumber", type: 'toggle', default: {id: "000000000000000000000101"}, hidden: true},
                            {path: "denominatorDelay", tKey: "denominatorPeriodDelay", wholePositiveNumber: true, default: 0, hidden: true},

                            {path: "preAggOperator", display: "tKey", translate: true, tKey: "intermediateMeshOperation", default: {id: "000000000000000000000001"}, hidden: true},
                            {path: "aggOperator", display: "tKey", translate: true, tKey: "operation", default: {id: "000000000000000000000001"}, hidden: true},
                            {path: "condOperator", display: "name", translate: true, tKey: "condition", filters: ["basicOperations"], required: true, hidden: true},
                            {path: "value", required: true, default: 0, hidden: true},
                            {path: "displayedResult", tKey: "retainedResult", hidden: true}
                        ]
                    }
                },
                {path: "habFunctionHabilitation", translateName: true, tKey: "habilitation", required: false},
                {path: "reason", hidden: true},
                {path: 'hasCalculatedData', hidden: true},
                {path: "dormant", tKey: "suspend"}
            ],
            onOpen: ({ store }) => {
                const state = store.getState()
                //const objectMode = state.ui.objectMode
                const hasCalculatedData = state.edit.object.data.hasCalculatedData

                const fields = [
                    'e_code', 'e_filterFields', 'e_groupAxes',
                    'e_timeWindow', 'e_preGroupAxes', 'e_alertFields'
                ]
                fields.forEach(field => store.dispatch(changeFieldDisabled(field, hasCalculatedData)))
            }
        }
    }
}

export const alertModule = {
    object: "AlertConfiguration",
    tKey: "mTitle_alertConfiguration",
    name: "Alerts",
    category: {
        path: "tracking",
        icon: 'briefcase'
    },
    defaultSortBy: "tName",
    defaultSortDirection: "ASC",
    chart: {
        type: "donutChart",
        keys: ["tName"],
        data: 'alertNb'
    },
    newable: false,
    updatable: false,
    removable: false,
    useSocketsOnFind: true,
    viewMap: {
        dt: [
            {path: "tName", tKey: "alertType", width: 300 },
            {path: "timeWindow", tKey: "periodicity", display: "tKey", width: 100},
            {path: "groupAxes", tKey: "analyzedObject", display: "tName", width: 100},
            {path: "alertNb", tKey: "number", width: 25},
            {path: "consultedAlertsNb", tKey: "analyzedAlertsNb", width: 25},
            {path: "themeNames", tKey: "indicator_plural", type: "creatableTags"},
            {path: "summedAlertsValues", tKey: "totalValue", type: "number", dynamic: true, tooltip: true, disableColumnsSort: true, width: 80}
        ],
        form: [
            {path: "tName", tKey: "alertType", writable: false},
            {path: "groupAxisName", tKey: "analyzedObject"},
            {path: "themesNameWithDelay", tKey: "indicator_plural", type: "creatableTags"},
            {path: "alertNb", tKey: "numberOfAlerts", disabled: true},
            {path: "brutSummedAlertsValues", tKey: "totalIndicatorsValue"},
            {
                path: "alertsInDatesForUser",
                tKey: "alert_plural",
                type: "dtObjects",
                applyBoard: true,
                maxRows: 20,
                // options: {searchBar: true},
                fields: [
                    {path: 'consulted', type: 'checkbox', tKey: 'Vu', width: 20, disableSort: true, enableSelectAll: true},
                    {path: "lastUpdate", tKey: "lastUpdate_short", type: "dateString", dateFormat: "YYYY-MM-DD HH:mm", initiallyNotVisible: true},
                    {path: "lastUpdateBy", tKey: "user", type: "object", display: "name", initiallyNotVisible: true},
                    {path: "dataPeriod", tKey: "period", width: 90},
                    {path: "meshWithoutGroupAxisLinks", tKey: "elements", type: "links", width: 380},
                    {path: "uniqValuesByDelay", tKey: "values", width: 40, dynamic: true, type: "customized", tooltip: true},
                ],
                subscriptions: {
                    onChange: (newValue, previousValue, {store}) => {
                        const validateButton = {
                            action: 'updateAlertStatus',
                            pristine: true,
                            bsStyle: 'primary',
                            tKey: 'updateAlertStatus',
                            type: 'action'
                        }

                        const returnButton = {
                            type: 'return',
                            tKey: 'return',
                            bsStyle: 'default'
                        }
                        store.dispatch(setFormButtons([validateButton, returnButton]))
                    }
                }
                // mField: {
                //     reloadList: true,
                // }
            },
            {path: "consultedAlertsNb", hidden: true},
        ],
        chart: ["tName", "alertNb"]
    },
    filters: [
        {
            type: "dateRange",
            default: [lastWeekISO, todayISO],
            path: "period",
            client: true,
            async: true,
            query: (context, callback) => {
                cacheOnContext(
                    context,
                    (e, context) => {
                        if (e) return callback(e);

                        const alertConfigIdsWithAlerts = _(context.data.alertsByConfigs)
                            .map(alertsByConfig => (
                                {
                                    _id: alertsByConfig.alertConfiguration._id,
                                    count: alertsByConfig.alerts.length
                                }
                            ))
                            .filter(idAndCount => idAndCount.count > 0)
                            .map("_id")
                            .value();

                        callback(e, {
                            _id: {$in: alertConfigIdsWithAlerts}
                        });
                    }
                );
            }
        }
    ]
}
