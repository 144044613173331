const moment = require("moment");
const _ = require("lodash");
const async = require("async");
const { translateName } = require("../../../utils/i18n");

const yesterdayISO = moment().subtract(1, "days").format("YYYY-MM-DD");

function getDateColumns(period, periodicity) {
    const columns = []
    const startDate = moment(period.start)
    const endDate = moment(period.end)

    let currentDate = startDate

    while (currentDate.format("YYYY-MM-DD") <= endDate.format("YYYY-MM-DD")) {
        if(periodicity.id === 'day') {
            columns.push(moment(currentDate).format('DD/MM/YYYY'))
            currentDate.add(1, 'day');
        } else {
            const start = moment(currentDate).startOf(periodicity.id)
            const end = moment(currentDate).endOf(periodicity.id)
            columns.push(`${moment(start).format('DD/MM/YYYY')} ${moment(end).format('DD/MM/YYYY')}`)
            currentDate = end.add(1, 'day')
        }
    }
    return columns
}

const groupByJoinedEntity = (alertGroupElems, alerts) => {
    return _.groupBy(alerts, alert => {
        const joinedGroup = alertGroupElems.map(groupElem => {
            switch (groupElem.joinedEntity) {
                case "SubStoreAxis":
                    return alert[groupElem.code]
                        ? `${alert[groupElem.code]}`
                        : `undefined_subStore`
                case "StoreAxis":
                    return alert.store
                        ? `${alert.store.code}`
                        : `undefined_store`
                case "ProductAxis":
                    return alert.product
                        ? `${alert.product.code}`
                        : `undefined_product`
                case "OrganizationAxis":
                    const organization = alert.organizations.find(o => o.organizationAxis.code === groupElem.code)
                    return organization ? `${organization.code}` : `undefined_org`
                case "FamilyAxis":
                    const family = alert.familys.find(o => o.familyAxis.code === groupElem.code)
                    return family ? `${family.code}` : `undefined_family`
                default:
                    return ""
            }
        })
        return _(joinedGroup).compact().join(" - ")
    })
}

const getMesh = (groupElements, alert) => {
    return _(groupElements)
        .map(groupElem => {
            switch (groupElem.joinedEntity) {
                case "SubStoreAxis":
                    return alert[groupElem.code]
                case "StoreAxis":
                    const storeCode = _.get(alert, 'store.code')
                    const storeName = _.get(alert, 'store.name')
                    return `${storeCode} - ${storeName}`
                case "ProductAxis":

                    return _.get(alert, 'product.name')
                case "OrganizationAxis":
                    const organization = alert.organizations.find(o => o.organizationAxis.code === groupElem.code)
                    return organization && organization.name
                case "FamilyAxis":
                    const family = alert.familys.find(o => o.familyAxis.code === groupElem.code)
                    return family && family.name
                default:
                    return ""
            }
        })
        .compact()
        .join(" | ")
}

const findAlertsAnalyse = (context, callback) => {

    const scopeId = _.get(context, 'data.scope.id');
    const periodicity = _.get(context, 'data.periodicity');
    const alertConf = _.get(context, 'data.alertConfiguration');
    const alertConfId = alertConf && alertConf.id;
    const alertGroupElems = _.get(context, 'data.alertAnalyseGroupElements', []);

    // in case both mandadory filters are empty, we return an empty list
    if (!alertConfId || alertGroupElems.length === 0) return callback(null, []);

    const scopeQuery = (scopeId && scopeId !== "null") ? {$or: [
        {"familys": new global.ObjectID(scopeId)},
        {"organizations": new global.ObjectID(scopeId)}
    ]} : {};

    const period = _.get(context, 'data.period', [yesterdayISO, yesterdayISO]).map(
        date => moment.utc(date.trim(), "YYYY-MM-DD")
    );
    const periodQuery = {
        //"date.gte": { $gte: period[0].toDate() },
        "date.lt": {
            $gt: period[0].toDate(),
            $lte: period[1].add(1, "days").toDate()
        },
    };

    const alertConfQuery = alertConfId ? {"alertConfiguration": new global.ObjectID(alertConfId)} : {};

    const alertContext = {
        user: context.user,
        group: context.group,
        t: context.t,
        tc: context.tc,
        query: {
            ...scopeQuery,
            ...periodQuery,
            ...alertConfQuery
        },
        fieldPath: [
            "id",
            "store.id",
            "product.id",
            "alertConfiguration.id",
            "groupAxes.id",
            "dataPeriod",
            'dataPeriodColumn',
            "lineCount",
            "organizations.organizationAxis.id",
            "organizations.organizationAxis.name",
            "familys.familyAxis.id",
            "familys.familyAxis.name"
        ]
    }

    async.parallel({
        alerts: callback => global.app.S.Alert.find(alertContext, callback),
        family: callback => global.app.S.Family.get(scopeId, {
            fieldPath: global.app.S.Family.deleteFieldPath,
            group: context.group,
            ignoreNotFound: true
        },callback),
        organization: callback => global.app.S.Organization.get(scopeId, {
            fieldPath: global.app.S.Organization.deleteFieldPath,
            group: context.group,
            ignoreNotFound: true
        },callback)
    }, (e, results) => {
        if (e) return callback(e);

        const {
            alerts,
            family,
            organization
        } = results

        const columns = getDateColumns({
            start: period[0],
            end: period[1]
        }, periodicity)

        const groupByDate = alerts => _.groupBy(alerts, alert => {
            if(periodicity.id === 'day') {
                return moment(alert.date.lt).subtract(1, "days").format('DD/MM/YYYY')
            }

            const start = moment(alert.date.gte).startOf(periodicity.id).format('DD/MM/YYYY')
            const end = moment(alert.date.lt).subtract(1, "days").endOf(periodicity.id).format('DD/MM/YYYY')

            return `${start} ${end}`
        })

        if(context.clientContext.accessId === "m-S-alertAnalysis-chart") {
            const groupedByDate = groupByDate(alerts)

            let dataKeys = []
            const chartLines = columns.map( date => {
                const groupedByMesh = groupByJoinedEntity(alertGroupElems, groupedByDate[date])
                const keys = Object.keys(groupedByMesh)
                dataKeys.push(...keys)
                return {
                    id: date,
                    date,
                    ..._.uniq(dataKeys).reduce((acc, key) => {
                        return {
                            ...acc,
                            [key]: groupedByMesh[key]?.length || 0
                        }
                    }, {})
                }
            })
            return callback(null, [{id: 'chartData', dataKeys: _.uniq(dataKeys), data: chartLines}])
        }


        const alertsGrouped = alertGroupElems.length > 0
            ? groupByJoinedEntity(alertGroupElems, alerts)
            : groupByJoinedEntity(alertConf.groupAxes, alerts)

        let perimeter = '';
        if(organization) {
            perimeter = organization.name
        }else if(family) {
            perimeter = family.name
        }

        const lines = Object.keys(alertsGrouped).map(
            key => {
                const objects = alertsGrouped[key]
                const object = objects[0]
                const objectsGroupedByDataPeriod = groupByDate(objects)
                return {
                    id: objects.map(o => o.id).join("_"),
                    mesh: alertGroupElems.length > 0 ? getMesh(alertGroupElems, object) : getMesh(alertConf.groupAxes, object),
                    alert: translateName(object.alertConfiguration.name),
                    total: objects.length,
                    periods: columns.reduce((acc, period) => {
                        const group = objectsGroupedByDataPeriod[period]
                        return {
                            ...acc,
                            [period]: group?.length || 0
                        }
                    }, {}),
                    /*
                    periods : Object.keys(objectsGroupedByDataPeriod).reduce((acc, key) => {
                        const group = objectsGroupedByDataPeriod[key]
                        return {
                            ...acc,
                            [key]: group.length
                        }
                    }, {}),

                     */
                    perimeter,
                }
            }
        )

        callback(null, lines)
    });
}

const getAlertAnalyse = (ids, context, callback) => {
    const alertGroupElem = _.get(context, 'data.alertAnalyseGroupElement');
    const scope = _.get(context, 'data.scope');
    global.app.S.Alert.find(
        {
            group: context.group,
            tc: context.tc,
            fieldPath: [
                "id", "code", "dataPeriod", "lineCount", "values", "meshWithoutGroupAxis",
                "alertConfiguration.themesName",
                "alertConfiguration.groupAxes.id",
                "alertConfiguration.groupAxisName"
            ],

            query: {
                _id: {$in: ids.split("_").map(id => global.ObjectID(id))}
            }
        },
        (e, alerts) => {
            const result = {
                id: new global.ObjectID(),
                tName: translateName(alerts[0].alertConfiguration.name),
                themesName: alerts[0].alertConfiguration.themesName,
                perimeter: scope && scope.completeName,
                mesh: getMesh(alertGroupElem, alerts[0]),
                groupAxisName: alerts[0].alertConfiguration.groupAxisName,
                alerts : alerts.map(alert => _.pick(alert, ["id", "code", "dataPeriod", "lineCount", "values", "meshWithoutGroupAxis"]))
            }
            callback(null, result);
        }
    )
}

export const entity = {
    name: "AlertAnalysis",
    type: null,
    fields: [
        "id",
        "mesh",
        "total",
        "alerts",
        "tName",
        "groupAxisName",
        "themesName",
        "alert",
        "perimeter",
        "periods"
    ],
    find: function(context, callback) {
        this.prepareContext(context, 'L', (error, context) => {
            if (error) return callback(error);
            else findAlertsAnalyse(context, (e, objects) => {
                if (e) {
                    const action = context.clientContext.accessId === "m-S-alertAnalysis-chart"
                        ? `fetchChart-failure-${context.user.id}${context.clientContext.accessId}`
                        : `fetchDT-failure-${context.user.id}${context.clientContext.accessId}`
                    global.ioSocket.emit(action, {error: e.message})
                } else {
                    const action = context.clientContext.accessId === "m-S-alertAnalysis-chart"
                            ? `fetchChart-success-${context.user.id}${context.clientContext.accessId}`
                            : `fetchDT-success-${context.user.id}${context.clientContext.accessId}`
                    global.ioSocket.emit(action, objects)
                }
            })
            callback()
        })
    },
    get: function(ids, context, callback) {
        this.prepareContext(context, 'R', (error, context) => {
            if (error) callback(error);
            else getAlertAnalyse(ids, context, callback);
        })
    }
}

export const module_ = {
    object: "AlertAnalysis",
    tKey: "mTitle_alertAnalysis",
    category: {
        path: "tracking",
        icon: 'briefcase'
    },
    defaultSortBy: "mesh",
    defaultSortDirection: "ASC",
    newable: false,
    updatable: false,
    removable: false,
    useSocketsOnFind: true,
    headerClassName: 'breakHeaderLine',
    headerRowClassName: 'KpDataTableOverFlowHidden',
    headerHeight: 45,
    chart: {
        type: "lineChart",
        keys: ["mesh"],
        data: 'total'
    },
    manualFilters: true,
    viewMap: {
        dt: [
            {path: "alert", tKey: 'alertConfiguration', initiallyNotVisible: true},
            {path: "perimeter", initiallyNotVisible: true},
            {path: "mesh", tKey: "groupedBy", width: 350},
            {path: "total", width: 35, tooltip: true},
            {path: "periods", tooltip: true, dynamic: true, disableColumnsSort: true}
        ],
        form: [
            {path: "tName", tKey: "alertConfiguration", writable: false},
            {path: "groupAxisName", tKey: "analysedObject", writable: false},
            {path: "themesName", tKey: "indicator_plural", writable: false},
            {path: "perimeter"},
            {path: "mesh", tKey: "groupedBy"},
            {
                path: "alerts",
                tKey: "alert_plural",
                type: "dtObjects",
                maxRows: 20,
                fields: [
                    {path: "dataPeriod", tKey: "period", width: 40},
                    {path: "meshWithoutGroupAxis", tKey: "for", width: 420},
                    {path: "values", tKey: "values", width: 50}
                ]
            }

        ],
        chart: ["mesh", "total"]
    },
    filters: [
        {
            path: "alertConfiguration",
            object: "AlertConfiguration",
            display: "tName",
            client: true,
            default: {id: null},
            fieldPath: ["tName", "groupAxes.code", "groupAxes.joinedEntity"],
            filters: ["alertConfigurationsByHabFunctions"],
            clearable: true,
            filter: true,
        },
        {
            path: "scope",
            object: "Scope",
            fieldPath: ['completeName', 'ancestorEntityName'],
            display: "completeName",
            client: true

        },
        {
            path: "alertAnalyseGroupElements",
            object: "GroupAxis",
            tKey: 'groupedBy',
            display: "tName",
            type: "tags",
            default: [],
            fieldPath: ["code", "tName", "joinedEntity"],
            client: true,
            filters: ["byAlertConf"],
            clearable: true
        },
        {
            path: "period",
            type: "dateRange",
            client: true,
            default: [yesterdayISO, yesterdayISO]
        },
        {
            path: "periodicity",
            object: "Periodicity",
            display: "name",
            client: true,
        },
    ]
}
