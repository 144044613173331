import _ from 'lodash'
import { combineReducers } from 'redux'
import { INITIALIZE_MODULE, SET_FILTER_MEMORY } from '../actions'
import { actionTypes as reduxFormActions } from 'redux-form'
import {FILTER_FORM} from '../components/Filter/FilterForm'

const FORM_INITIALIZE = reduxFormActions.INITIALIZE
const FORM_CHANGE = reduxFormActions.CHANGE

const memory = (state= {}, action) => {
    switch (action.type) {
        case FORM_INITIALIZE:
            const savedFilters = sessionStorage.getItem('filters')
            const sessionFilters = savedFilters ? JSON.parse(savedFilters) : {};
            return (action.meta.form === FILTER_FORM)
                ? { ...state, ...action.payload, ...sessionFilters }
                : state
        case FORM_CHANGE:
            if(action.meta.form === FILTER_FORM) {
                const filters = _.set({ ...state }, action.meta.field, action.payload)
                sessionStorage.setItem('filters', JSON.stringify(filters))
                return filters
            }
            return state
        case SET_FILTER_MEMORY:
            const filters = {
                ...state,
                ...action.query
            }
            sessionStorage.setItem('filters', JSON.stringify(filters))
            return filters
        default:
            return state
    }
}

const object = (state={}, action) => {
    switch (action.type) {
        case INITIALIZE_MODULE:
            return {}
        case FORM_INITIALIZE:
            return (action.meta.form === FILTER_FORM)
                ? action.payload
                : state
        case FORM_CHANGE:
            return (action.meta.form === FILTER_FORM)
                ? _.set({ ...state }, action.meta.field, action.payload)
                : state
        default:
            return state
    }
}

const fields = (state=[], action) => {
    switch (action.type) {
        case INITIALIZE_MODULE:
            return _.get(action, 'module.filter.fields', state)
        default:
            return state
    }
}

const dataLists = (state=[], action) => {
    switch (action.type) {
        case INITIALIZE_MODULE:
            return _.get(action, 'module.filter.dataLists')
        default:
            return state
    }
}

const filter = combineReducers({
    fields,
    dataLists,
    object,
    memory
})

export default filter
