import _ from 'lodash'
import moment from 'moment'
import Errors from '../../utils/Errors'
import {
    getStatusString,
    getStepStatus,
    getAlertHabFunctions,
    getAlertButtonsForStatusUser,
    getStepTkey,
    getActiveStep,
    updateStatusUsers,
    workflowManager
} from './workflow'
import { basicContext } from '../../utils/contextUtils'
import {
    changeFieldRequired,
    setFieldEdition,
    setFieldListOptions,
    setFieldVisibility
} from '../../../apps/KpModule/actions'
import { GET_OBJECT_SUCCESS } from '../../../apps/KpModule/actions/api'

const twoMonthEarlierISO = () => {
    console.log("twoMonthEarlierISO")
    return moment()
        .subtract(2, 'months')
        .format('YYYY-MM-DD')
}

const todayISO = () => moment().format('YYYY-MM-DD')

async function getUserHabFunctionsAsync(user, context) {
    return new Promise((resolve, reject) => {
        global.app.S.User.getUserHabFunctions(user, context, (error, result) => {
            if (error) {
                reject(error);
            } else {
                resolve(result);
            }
        });
    });
}

async function findAlertProcessings(context) {


    const dates = _.get(context, 'data.period', [
        twoMonthEarlierISO(),
        todayISO()
    ]).map(date => moment.utc(date.trim(), 'YYYY-MM-DD'))

    const datesQuery = {
        'date.gte': { $gte: dates[0].toDate() },
        'date.lt': { $lte: dates[1].add(1, 'days').toDate() }
    }

    const user = new global.ObjectID(context.user.id)

    let activeQuery
    switch (_.get(context, 'data.workflowFilter.id')) {
        case 'alertToProcess':
            activeQuery = { active: true }
            break
        case 'alertInProgress':
            activeQuery = { step: { $nin: ['risk', 'noRisk'] } }
            break
        case 'closedAlertRisk':
            activeQuery = { step: 'risk' }
            break
        case 'closedAlertNoRisk':
            activeQuery = { step: 'noRisk' }
            break
        default:
            activeQuery = {}
    }

    const statusUserContext = {
        ...basicContext(context),
        query: {
            ...datesQuery,
            ...activeQuery,
            user,
        },
        fieldPath: [
            'id',
            'alertConfiguration.id',
            'alertConfiguration.themeNames',
            'alert.id',
            'alert.store.name',
            'alert.store.fullName',
            'alert.organizations.name',
            'alert.organizations.organizationAxis.useInScope',
            'alert.mesh',
            'alert.values',
            'alert.reason.id',
            'alert.reason.tName',
            'alert.dataPeriod',
            'alert.details',
            'alert.comments.id',
            'alert.comments.text',
            'alert.comments.date',
            'alert.lastComment',
            'alert.alertCreationTime',
            'alert.closingDate',
        ]
    }

    const statusUsers = await global.app.S.StatusUser.find(statusUserContext)

    // we group status users by alert id to ensure we only list one line per alert
    // the alert processing id is the alert id, to help the save operation
    return await Promise.all(
        _(statusUsers)
            .groupBy('alert.id')
            .map(statusUsers => statusUsers[0])
            .map(async ({ alertConfiguration = {}, alert = {} }) => {
                const statusHabFunctions = await getAlertHabFunctions(
                    alert,
                    context
                )

                const alertConf = await global.app.S.AlertConfiguration.get(
                    alertConfiguration.id,
                    {
                        group: context.group,
                        fieldPath: ["name", "themeNames"],
                    }
                )

                const currentStep = _.get(alert, 'workflow.step')
                const sortedComments = alert.comments.sort((a, b) => new Date(b.date) - new Date(a.date));
                const lastComment = sortedComments[0]
                return {
                    ..._.pick(alert, [
                        'id',
                        'reason',
                        'store',
                        'lastComment',
                        'dataPeriod',
                        'mesh',
                        'values',
                        'details',
                        'alertCreationTime',
                        'closingDate',
                    ]),
                    comment:lastComment && lastComment.text,
                    organizations: alert.organizations.filter(org => org.organizationAxis.useInScope),
                    alertConfigurationName: context.translateName(
                        alertConf.name
                    ),
                    themeNames: alertConf.themeNames,
                    statusString: getStatusString(alert, context),
                    stepStatus: getStepStatus(alert),
                    disableGroupedAction: ['pending', 'risk', 'noRisk'].includes(currentStep),
                    statusHabFunctions
                }
            })
            .value()
    )
}

async function getAlertProcessing(alertId, context) {
    const activeQuery =
        _.get(context, 'data.workflowFilter.id') === 'alertToProcess'
            ? { active: true }
            : {}

    const statusUserQuery = {
        alert: new global.ObjectID(alertId),
        user: new global.ObjectID(_.get(context, 'user.id')),
        ...activeQuery
    }

    // ignore not found is used to get nothing after action execution
    const statusUserContext = {
        ...basicContext(context),
        ignoreNotFound: context.ignoreNotFound,
        fieldPath: [
            'id',
            'alertConfiguration.id',
            'alertConfiguration.reason.id',
            'alertConfiguration.themeNames',
            'alert.id',
            'alert.organizations.name',
            'alert.organizations.organizationAxis.useInScope',
            'alert.mesh',
            'alert.lastComment',
            'alert.alertCreationTime',
            'alert.closingDate',
            'alert.store.fullName',
            'alert.values',
            'alert.reason.id',
            'alert.reason.tName',
            'alert.details',
            'alert.dataPeriod',
            'alert.keyAndValues',
            'alert.ticketsLines',
            'alert.files.id',
            'alert.files.filename',
            'alert.files.date',
            'alert.files.user',
            'alert.comments.id',
            'alert.comments.text',
            'alert.comments.date',
            'alert.comments.user'
        ]
    }

    const statusUser = await global.app.S.StatusUser.get(
        statusUserQuery,
        statusUserContext
    )
    if (!statusUser) return null

    const { alert = {}, alertConfiguration = {} } = statusUser

    // do the asynchronous calls in parallel
    const [currentStep, statusHabFunctions, buttons] = await Promise.all([
        getActiveStep(alert, context),
        getAlertHabFunctions(alert, context),
        getAlertButtonsForStatusUser(statusUser, context)
    ])

    console.log('step', currentStep)

    // this is the list of ids that will be proposed on the reason dropDown
    const alertConfigurationReasonsIds = alertConfiguration.reason
        .filter(reason => {
            return !reason.automatic || reason.id === _.get(alert, 'reason.id')
        })
        .map(reason => reason.id)

    return {
        ..._.pick(alert, [
            'id',
            'store',
            'dataPeriod',
            'mesh',
            'values',
            'reason',
            'alertCreationTime',
            'closingDate',
            'lastComment',
            'details',
            'keyAndValues',
            'ticketsLines'
        ]),
        organizations: alert.organizations.filter(org => org.organizationAxis.useInScope),
        isManager: ['review', 'pending'].includes(currentStep),
        alertConfigurationName: context.translateName(alertConfiguration.name),
        themeNames: alertConfiguration.themeNames,
        alertConfigurationReasonsIds,
        files: alert.files.map(
            _.partialRight(_.pick, ['id', 'filename', 'date', 'user'])
        ),
        comments: alert.comments.map(
            _.partialRight(_.pick, ['id', 'text', 'date', 'user'])
        ),
        stepStatus: getStepStatus(alert),
        statusString: getStatusString(alert, context),
        statusHabFunctions,
        buttons
    }
}

async function saveAlertProcessing(alertProcessing = {}, context = {}) {
    // don't save anything without the basic data
    if (!context.action || !alertProcessing.id) return alertProcessing
    if (!alertProcessing.reason) throw new Errors.ValidationError('reasonIsMandatory')

    // alertProcessing id is an alert id
    const alert = await global.app.S.Alert.get(alertProcessing.id, {
        ...basicContext(context),
        fieldPath: ['id', 'workflow.step', 'workflow.order', 'comments.id']
    })
    const reason = await global.app.S.Reason.get(alertProcessing.reason.id, {
        ...basicContext(context),
        fieldPath: ['id', 'name']
    })
    const currentStatus = _.pick(alert.workflow, ['step', 'order'])

    const user = context.user && {
        id: context.user.id,
        name: context.user.name
    }
    const date = moment().format('YYYY-MM-DD HH:mm')
    const commentTexts = [
        `${context.tc(context.action)} ${context.tc(
            getStepTkey(currentStatus.step)
        )}`,
        context.translateName(reason.name)
    ]

    const userComments = _(alertProcessing.comments)
        .filter(comment => !alert.comments.some(oldComment => oldComment.id === comment.id))
        .orderBy('date', 'desc')
        .value()

    const lastCommentObject = userComments[0]

    const lastCommentQuery = lastCommentObject ? {lastComment: lastCommentObject.text} : {}

    const comments = [
        ...(alertProcessing.comments || []),
        ...commentTexts.map(text => ({ user, date, text }))
    ]

    // use the platform to validate and save alert fields that can change
    await global.app.S.Alert.save(
        {
            id: alert.id,
            ..._.pick(alertProcessing, ['reason', 'details', 'files']),
            comments
        },
        {
            ...basicContext(context),
            action: context.action,
            fieldPath: [
                'id',
                'reason.id',
                'reason.automatic',
                'details',
                'comments.id',
                'comments.text',
                'comments.date',
                'comments.user',
                'files.id',
                'files.filename',
                'files.date',
                'files.user'
            ]
        }
    )

    const nextStatus = workflowManager.getNextStatus(
        currentStatus,
        context.action,
        alert.workflow.maxOrders
    )

    const isClosingAction = ['risk', 'noRisk'].includes(nextStatus.step)

    const closingDateSetQuery = isClosingAction ? {closingDate: new Date()} : {}

    await updateStatusUsers(alert, nextStatus, context)

    // directly set the new status on the alert collection
    const alertCollection = global.app.S.Alert.collection
    await alertCollection.updateOne(
        { _id: new global.ObjectID(alertProcessing.id) },
        {
            $set: {
                'workflow.step': nextStatus.step,
                'workflow.order': nextStatus.order,
                'workflow.lastUpdate': new Date(),
                ...lastCommentQuery,
                ...closingDateSetQuery
            }
        }
    )

    return await getAlertProcessing(alertProcessing.id, {
        ...context,
        ignoreNotFound: true
    })
}

export const entities = [
    {
        name: 'StatusUser',
        fields: [
            { path: 'step', index: true },
            { path: 'order', type: 'integer', index: true },
            { path: 'active', type: 'boolean', index: true },
            { type: 'User', index: true },
            { type: 'Alert', index: true },
            { type: 'AlertConfiguration', index: true },
            'Habilitation',
            { path: 'date.gte', type: 'date', index: true },
            { path: 'date.lt', type: 'date', index: true }
        ]
    },
    {
        name: 'StaticWorkflow',
        fields: [
            'step',
            { path: 'order', type: 'integer' },
            { path: 'active', type: 'boolean' },
            { type: 'Alert', index: true },
            { type: 'AlertConfiguration', index: true },
            'Habilitation',
            { path: 'date.gte', type: 'date' },
            { path: 'date.lt', type: 'date' }
        ]
    },
    {
        name: 'AlertProcessing',
        type: null,
        facets: ['comments', 'files'],
        fields: [
            {type: 'Reason', nullable: true}
        ],
        find: function(context, callback) {
            this.prepareContext(context, 'L')
                .then(context => findAlertProcessings(context))
                .then(objects => callback(null, objects))
                .catch(error => callback(error))
        },
        get: function(id, context, callback) {
            this.prepareContext(context, 'R')
                .then(context => getAlertProcessing(id, context))
                .then(object => callback(null, object))
                .catch(error => callback(error))
        },
        save: function(newObject, context, callback) {
            this.prepareContext(context, 'S')
                .then(context => saveAlertProcessing(newObject, context))
                .then(object => callback(null, object))
                .catch(error => callback(error))
        }
    }
]

export const module_ = {
    object: 'AlertProcessing',
    tKey: 'mTitle_alertProcessing',
    category: 'tracking',
    defaultSortBy: 'alertConfigurationName',
    defaultSortDirection: 'ASC',
    newable: false,
    removable: false,
    workflowActions: ['validate', 'question', 'refuse'],
    viewMap: {
        dt: [
            { path: 'alertConfigurationName', tKey: 'alert', width: 200 },
            {
                path: 'dataPeriod',
                tKey: 'period',
                width: 100,
                initiallyNotVisible: true
            },
            { path: 'organizations', type: 'objectArray', display: 'name', initiallyNotVisible: true},
            { path: 'store', type: 'object', display: 'fullName', initiallyNotVisible: true },
            { path: 'mesh', tKey: 'for', width: 320 },
            { path: "themeNames", tKey: "indicator_plural", initiallyNotVisible: true },
            { path: 'values', width: 100 },
            { path: 'alertCreationTime', tKey: 'detectionDate', type: 'date', initiallyNotVisible: true },
            { path: 'reason', display: 'tName', width: 230 },
            { path: 'details', initiallyNotVisible: true},
            //{ path: 'comment', initiallyNotVisible: true},
            { path: 'stepStatus', tKey: 'step', initiallyNotVisible: true },
            {
                path: 'statusString',
                tKey: 'status',
                type: 'translatedText',
                width: 100,
                initiallyNotVisible: true
            },
            {
                path: 'statusHabFunctions',
                tKey: 'functions',
                initiallyNotVisible: true
            },
            { path: 'closingDate', type: 'date', initiallyNotVisible: true },
            { path: 'lastComment', tKey: 'lastUserComment', initiallyNotVisible: true },
            { path: 'disableGroupedAction', hidden : true }
        ],
        form: [
            { path: 'alertConfigurationName', tKey: 'alert', type: 'readOnly' },
            { path: 'dataPeriod', tKey: 'period', type: 'readOnly' },
            { path: 'mesh', tKey: 'for', type: 'readOnly' },
            {
                path: 'keyAndValues',
                tKey: 'indicator_plural',
                type: 'readOnly'
            },
            {
                path: 'reason',
                display: 'tName',
                required: true,
                subscriptions: {
                    onChange: (newValue, oldValue, {formInitialize, getObjectSuccessAction, module}) => {
                        if(!formInitialize && !getObjectSuccessAction && newValue) {
                            const detailsField =  module.viewMap.form.fields.find(field => field.id === 'e_details')
                            detailsField.setValue("")
                        }
                    }
                }
            },
            { path: 'details', type: 'textarea', required: true},
            {
                path: 'ticketsLines',
                tKey: 'ticketLines',
                type: 'dtObjects',
                fields: [
                    { path: 'numLigne', tKey: 'lineNumber', width: 30 },
                    { path: 'cashier', tKey: 'cashier', width: 60 },
                    { path: 'product', tKey: 'product', width: 250 },
                    { path: 'productVolume', tKey: 'volume', width: 50 },
                    { path: 'themeKey', tKey: 'theme', width: 300 },
                    { path: 'themeValue', tKey: 'value', width: 50 }
                ]
            },

            { path: 'files', tKey: 'file_plural' },
            { path: 'comments', tKey: 'comment_plural' },

            { path: 'isManager', hidden: true },
            { path: 'alertConfigurationReasonsIds', hidden: true },
            { path: 'buttons', hidden: true }
        ]
    },
    actionSubscriptions: [
        {
            actionType: GET_OBJECT_SUCCESS,
            subscription: ({ store }) => {
                const state = store.getState()

                // those are the ids of the reasons allowed for this alert
                const alertConfigurationReasonsIds = _.get(
                    state,
                    'edit.object.data.alertConfigurationReasonsIds',
                    []
                )

                store.dispatch(
                    setFieldListOptions(
                        'e_reason',
                        alertConfigurationReasonsIds
                    )
                )

                const userIsManager = _.get(state, 'edit.object.data.isManager', false)

                const ticketLines = _.get(
                    state,
                    'edit.object.data.ticketsLines',
                    []
                )

                const ticketLinesIsEmpty = ticketLines.length === 0
                store.dispatch(setFieldVisibility('e_ticketsLines', !ticketLinesIsEmpty))
                store.dispatch(setFieldEdition('e_reason', userIsManager))
                store.dispatch(setFieldEdition('e_details', userIsManager))
                store.dispatch(changeFieldRequired('e_reason', userIsManager))
                store.dispatch(changeFieldRequired('e_details', userIsManager))
            }
        }
    ],
    filters: [
        {
            object: 'WorkflowFilter',
            path: 'workflowFilter',
            tKey: 'status',
            display: 'tKey',
            translate: true,
            clearable: true,
            client: true
        },
        {
            path: 'period',
            type: 'dateRange',
            client: true,
            default: [twoMonthEarlierISO(), todayISO()]
        }
    ]
}
